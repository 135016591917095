@use 'vars' as psv;

.psv-notification {
    position: absolute;
    z-index: psv.$notification-zindex;
    bottom: psv.$notification-position-from;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0 2em;
    opacity: 0;
    transition-property: opacity, bottom;
    transition-timing-function: ease-in-out;
    transition-duration: psv.$notification-animate-delay;

    &-content {
        max-width: 50em;
        background: psv.$notification-background;
        border-radius: psv.$notification-radius;
        padding: psv.$notification-padding;
        font: psv.$notification-font;
        color: psv.$notification-text-color;
    }

    &--visible {
        opacity: 100;
        bottom: psv.$notification-position-to;
    }
}
