@use 'sass:list';
@use 'vars' as psv;

.psv-navbar {
    display: flex;
    position: absolute;
    z-index: psv.$navbar-zindex;
    bottom: -#{psv.$navbar-height};
    left: 0;
    width: 100%;
    height: psv.$navbar-height;
    background: psv.$navbar-background;
    transition: bottom ease-in-out 0.1s;
    font: psv.$caption-font;
    cursor: default;

    &--open {
        bottom: 0;
    }

    &,
    & * {
        box-sizing: content-box;
    }
}

.psv-button {
    flex: 0 0 auto;
    padding: psv.$buttons-padding;
    position: relative;
    cursor: pointer;
    height: psv.$buttons-height;
    width: psv.$buttons-height;
    background: psv.$buttons-background;
    color: psv.$buttons-color;

    &--active {
        background: psv.$buttons-active-background;
    }

    &--disabled {
        pointer-events: none;
        opacity: psv.$buttons-disabled-opacity;
    }

    &-svg {
        width: 100%;
        transform: scale(1);
        transition: transform psv.$buttons-hover-scale-delay ease;
        vertical-align: initial;
    }
}

.psv-button:not(.psv-button--disabled):focus-visible {
    outline: psv.$element-focus-outline;
    outline-offset: -#{list.nth(psv.$element-focus-outline, 1)};
}

.psv-container:not(.psv--is-touch) .psv-button--hover-scale:not(.psv-button--disabled):hover .psv-button-svg {
    transform: scale(psv.$buttons-hover-scale);
}

.psv-move-button + .psv-move-button {
    margin-left: -#{psv.$buttons-padding};
}

.psv-download-button {
    color: psv.$buttons-color !important;
    text-decoration: none !important;

    &::before, &::after {
        display: none !important;
    }
}

.psv-custom-button {
    width: auto;
    min-width: psv.$buttons-height;

    &--no-padding {
        padding: 0;
        height: 100%;
    }
}

.psv-caption {
    flex: 1 1 100%;
    color: psv.$caption-text-color;
    overflow: hidden;
    text-align: center;
    cursor: unset;
    padding: unset;
    height: unset;
    width: unset;

    &-content {
        display: inline-block;
        padding: psv.$buttons-padding;
        white-space: nowrap;
    }
}
